<template>
    <section id="content">
        <div class="container">

            <div class="card" id="profile-main">
                <div class="pmb-block">
                    <div class="pmbb-header">
                        <h2><i class="zmdi zmdi-account m-r-5"></i> Basic Information</h2>
                    </div>
                    <div class="pmbb-body p-l-30">
                        <div class="pmbb-view">
                            <dl class="dl-horizontal">
                                <dt>Full Name</dt>
                                <dd>{{ staff.fullname }}</dd>
                            </dl>
                            <dl class="dl-horizontal">
                                <dt>Gender</dt>
                                <dd>{{ staff.gender }}</dd>
                            </dl>
                            <dl class="dl-horizontal">
                                <dt>Birthday</dt>
                                <dd>{{ staff.birthday }} <strong>({{ staff.age }})</strong> </dd>
                            </dl>
                            <dl class="dl-horizontal">
                                <dt>Martial Status</dt>
                                <dd>{{ staff.marital_status }}</dd>
                            </dl>
                            <dl class="dl-horizontal" v-if="staff.marital_status == 'Married'">
                                <dt>Marriage Anniversary</dt>
                                <dd>{{ staff.marriage_anniversary }} <strong>({{ staff.marriedFor }})</strong> </dd>
                            </dl>
                            <dl class="dl-horizontal">
                                <dt>SSNIT Number</dt>
                                <dd>{{ staff.ssnit }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            staffId: this.$route.params.staffId,
            staff: {},
        }
    },
    created(){
        this.getStaff()
    },
    methods: {
        getStaff(){
            this.$http.get("/staff/view", {
                params: {
                    staffId: this.staffId
                }
            }).then(response => {
                this.staff = response.data.staff
            })
        }
    },
}
</script>
